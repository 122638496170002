<template>
  <BrToolBar
    @search="buscar"
    :configFilter="configFilter"
    :labelTextField="labelTextField"
    :addlFilter="addlFilter"
    :itemsSelect="itemsSelect">
    <BrModalCadastro />
  </BrToolBar>
</template>

<script>
/**
 * MIXIN
 */
import toolbar from '@/views/mixins/toolbar'

export default {
  name: 'ToolBarCadastroPaceiro',
  mixins: [toolbar],
  components: { 
    BrModalCadastro: () => import('./modalCadastro'),
    BrToolBar: () => import('@/views/components/toolbar')
  }
}
</script>